var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.logs.data,"server-items-length":_vm.logs.total,"options":_vm.options,"headers":_vm.headers,"loading":_vm.loading,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.trainingId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTrainingName(item.trainingId))+" ")]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserName(item.userId))+" ")]}},{key:"item.granted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.granted ? 'Yes' : 'No')+" ")]}},{key:"item.test",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.test ? 'Yes' : 'No')+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dayjs(item.createdAt).format('D/M/YYYY H:mma'))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }